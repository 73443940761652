@import 'styles/includes';

.TitleBlock {
    $root: &;
    @extend %container;
    margin: 4rem auto 2rem auto;

    &__Title {
        font-family: $font-family-bold;
        font-size: 3rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        letter-spacing: -0.135rem;
        text-align: center;

        @include media(xl) {
            font-size: 4.5rem;
        }

        &--Underline {
            @include media(xl) {
                text-decoration: underline;
                text-decoration-thickness: 0.4rem;
                text-underline-offset: 0.5rem;
                text-decoration-color: $black;
            }
        }
    }

    &__Icon {
        margin: 1rem auto 0 auto;
        width: 1.5rem;
        height: 1.5rem;

        @include media(xl) {
            margin: 2rem auto 0 auto;
            width: 2rem;
            height: 2rem;
        }
    }
}
